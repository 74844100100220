import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Life Science Marketing | Scitodate Agency </title>
                    <meta name="description" content="AI Driven marketing agency for Life science and pharmaceutical industries." />
                    <meta name="keywords" content="life science marketing, pharmaceutical marketing, life science market analysis, pharmaceutical market analysis, life science lead generation, lead generation for scientist, lead generation for pharmaceutical"/>
                    <meta property="og:description" content="AI Driven marketing agency for Life science and pharmaceutical industries."/>
                    <meta property="og:title" content="Life Science Marketing | Scitodate Agency"/>
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
