import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Buyer Persona Creation',
        description: 'Craft a razor-sharp buyer persona to precisely target your audience, ensuring maximum impact and engagement in the life science and pharmaceutical sectors.'
    },
    {
        icon: <FiLayers />,
        title: 'Generating Leads',
        description: 'Leveraging our AI platform, we meticulously analyze over 100 million scientist profiles to curate the most optimal list for your campaign, ensuring unparalleled precision and success.'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing Outreach',
        description: 'Utilizing our advanced AI engine, we engage leads on a profound level, seamlessly introducing your products and services through LinkedIn or email, fostering meaningful connections and driving results.'
    },
    {
        icon: <FiMonitor />,
        title: 'Market Analysis',
        description: 'Diligently analyzing the market to ensure optimal results, we meticulously define focus areas, enabling precise targeting of the most valuable accounts in the life science and pharmaceutical sectors.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services',
        description = 'At Scitodate, we offer a comprehensive suite of services tailored to the life science and pharmaceutical sectors:';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/contact"><span className="text">Request a Quote</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
