import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/63eb5d228a6e10805c6949de_Thermo Fisher Black-p-500.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/63eb5d221ef0a488609b5166_Merck Black-p-500.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/63eb5d22ae57fd5e3845b7a9_Charles River Black-p-500.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/63eb5d2270fa5f63a7705c84_Ametek Black-p-500.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/63eb5d2299744fde4f1c6717_Bruker Black-p-500.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/63eb5d22a5edc00308e64ef8_Lumicks Black-p-500.png" alt="Logo Images"/>
                    </li>

                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;