import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>"Working with Scitodate allowed us to streamline our lead generation process, making it easier and more complete. Their team is always willing to help and make our feedback actually useful."</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Joao Rosa</span> - Business Manager Scientific Instruments at Insplorion AB</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>"I generated sales pipeline of over $ 34M during 2021 using Scitodate."</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Mariam Iftikhar </span> - Sales and Business Development Specialist at LUMICKS</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>"Scitodate lets us call information exactly how we need to and eliminates days of manual research. We cannot wait to put this knowledge to work for us."</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Kenya Ebersole </span> - Marketing Communications Specialist at Vision Research Ametek</h6>
                                    </div>
                                </div>
                            </TabPanel>


                            
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/63eba43b1c520705a610bfd7_quote_1.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/63eba43b09ae2ab17c6e2bc7_quote_2.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/63eba43b20beacc5be993077_quote_3.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>

                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;